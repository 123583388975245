<template lang="pug">
  b-card.account-profile-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('account/profile.menus.my_profile') }}
    b-form.form-wc.form-account-profile-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-profile-appellation(
              :label="$t('activerecord.attributes.account/profile.appellation')"
              label-for='profile-appellation'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#profile-appellation.form-input-first.shadow-sm(
                name='appellation'
                type='text'
                autocomplete='appellation'
                :placeholder="$t('account/profile.placeholders.appellation')"
                :state='formValidationState($v.form.data.attributes.appellation)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.appellation.$model'
                aria-describedby='profile-appellation-feedback'
                trim)
              wc-forms-if#profile-appellation-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.appellation'
                :remote="formRemoteInvalidFeedback('appellation')"
                :validators='{ maxLength: {} }')

            b-form-group#form-profile-firstname(
              :label="$t('activerecord.attributes.account/profile.firstname')"
              label-for='profile-firstname'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#profile-firstname.form-input-middle.shadow-sm.is-required(
                name='firstname'
                type='text'
                autocomplete='firstname'
                :placeholder="$t('account/profile.placeholders.firstname')"
                :state='formValidationState($v.form.data.attributes.firstname)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.firstname.$model'
                aria-describedby='profile-firstname-feedback'
                trim)
              wc-forms-if#profile-firstname-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.firstname'
                :remote="formRemoteInvalidFeedback('firstname')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-profile-lastname(
              :label="$t('activerecord.attributes.account/profile.lastname')"
              label-for='profile-lastname'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#profile-lastname.form-input-middle.shadow-sm.is-required(
                name='lastname'
                type='text'
                autocomplete='lastname'
                :placeholder="$t('account/profile.placeholders.lastname')"
                :state='formValidationState($v.form.data.attributes.lastname)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.lastname.$model'
                aria-describedby='profile-lastname-feedback'
                trim)
              wc-forms-if#profile-lastname-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.lastname'
                :remote="formRemoteInvalidFeedback('lastname')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-profile-birth_date(
              :label="$t('activerecord.attributes.account/profile.birth_date')"
              label-for='profile-birth_date'
              label-cols-md='2'
              label-align-md='right')
              flat-pickr#profile-birth_date.form-control.form-input-middle.shadow-sm(
                name='birth_date'
                v-model.trim='$v.form.data.attributes.birth_date.$model'
                :config='form.datetime.birth_date.config'
                :class='formValidationClass($v.form.data.attributes.birth_date)'
                :placeholder="$t('account/profile.placeholders.birth_date')")
              wc-forms-if#profile-lastname-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.birth_date'
                :remote="formRemoteInvalidFeedback('birth_date')"
                :validators='{ dateFormat: {}, maxLength: {} }')

            b-form-group#form-profile-gender(
              :label="$t('activerecord.models.gender.one')"
              label-for='profile-gender_id'
              label-cols-md='2'
              label-align-md='right')
              multiselect#profile-gender_id.form-input-middle.shadow-sm.custom-select(
                name='gender'
                v-model='profileGenderIdSelected'
                :options='profileGenderIdOptions'
                :class='formValidationClass($v.form.data.attributes.gender_id)'
                :close-on-select='true'
                :clear-on-select='false'
                :placeholder="$t('account/profile.placeholders.gender')"
                label='label'
                track-by='value'
                :searchable='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options') }}
              wc-forms-if#profile-gender_id-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.gender_id'
                :remote="formRemoteInvalidFeedback('gender')"
                :validators='{}')

            b-form-group#form-profile-description(
              :label="$t('activerecord.attributes.shared.description')"
              label-for='profile-description'
              label-cols-md='2'
              label-align-md='right')
              b-form-textarea#profile-description.form-input-last.shadow-sm(
                name='description'
                :placeholder="$t('account/profile.placeholders.description')"
                :state='formValidationState($v.form.data.attributes.description)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.description.$model'
                aria-describedby='profile-description-feedback'
                rows='4'
                max-rows='8'
                trim)
              wc-forms-if#profile-description-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.description'
                :remote="formRemoteInvalidFeedback('description')"
                :validators='{ minLength: {}, maxLength: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-profile-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiProfile from '@services/api/account/profile'
import { Form, mapSelects } from '@common/form'
import { dateFormat } from '@common/form/validations'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'account-profile-form',
  mixins: [Form],
  components: {
    WcFormsButtons,
    flatPickr,
  },
  computed: {
    ...mapSelects([
      {
        name: 'profileGenderId',
        default: 'relationships.gender.data.id',
        attribute: 'attributes.gender_id',
        collection: { data: 'genders.data', key: 'id', value: 'attributes.name' },
      },
    ]),
    apiParams() {
      return {
        get: [],
        update: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            appellation: {
              maxLength: maxLength(this.$wc.conf.limits.max_short_field),
              remote: () => this.formRemoteValid('appellation'),
            },
            firstname: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_name),
              remote: () => this.formRemoteValid('firstname'),
            },
            lastname: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_name),
              remote: () => this.formRemoteValid('lastname'),
            },
            birth_date: {
              dateFormat: dateFormat(this.$t('date.view.format')),
              maxLength: maxLength(this.$wc.conf.limits.max_date),
              remote: () => this.formRemoteValid('birth_date'),
            },
            gender_id: {
              remote: () => this.formRemoteValid('gender'),
            },
            description: {
              minLength: minLength(this.$wc.conf.limits.min_description),
              maxLength: maxLength(this.$wc.conf.limits.max_description),
              remote: () => this.formRemoteValid('description'),
            },
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiProfile,
      apiModel: 'profile',
      formBootstrapDefaults: {
        all: {
          datetime: {
            birth_date: {
              config: {
                allowInput: true,
                maxDate: this.$dayjs.tz().subtract(this.$wc.conf.limits.min_birth_years, 'year'),
                ariaDateFormat: this.$t('date.formats.default').replace(/%/gi, ''),
                dateFormat: this.$t('date.formats.default').replace(/%/gi, ''),
                locale:
                  this.$store.getters['ui/getLocale'] !== this.$wc.conf.base.default_locale
                    ? require(`flatpickr/dist/l10n/${this.$store.getters['ui/getLocale']}.js`)
                        .default[this.$store.getters['ui/getLocale']]
                    : this.$wc.conf.base.default_locale,
              },
            },
          },
        },
      },
    }
  },
}
</script>
